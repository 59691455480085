/* You can add global styles to this file, and also import other style files */
@import "./node_modules/bootstrap/scss/bootstrap.scss";
@import './node_modules/bootstrap/scss/mixins';
@import './node_modules/bootstrap/scss/variables';
@import './node_modules/bootstrap/scss/functions';
@import 'theme/custom-table';
@import 'theme/custom-nav';
@import 'theme/variables';
//@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";
//
//body {
//  font-family: 'Poppins', sans-serif;
//}


html {
  body {
    color: $custom-dark-gray;
    background-color: $bg-color-blue;
    font-family: "poppins-regular";

    .light-small-text {
      color: $light-text-color;
      font-size: 12px;
      font-weight: 400;
    }

    .light-big-text {
      color: $light-text-color;
      font-size: 20px;
      font-weight: 400;
    }

    // Placeholders are slightly more dim
    .form-control::-webkit-input-placeholder {
      color: inherit;
      opacity: 0.3;
    }

    .form-control::-moz-placeholder {
      color: inherit;
      opacity: 0.3;
    }

    .form-control:-ms-input-placeholder {
      color: inherit;
      opacity: 0.3;
    }

    // All links have a somewhat darker color
    a {
      color: #0d47bc;
      transition: 0.3s all ease;

      &:hover {
        color: #78dedf;
        text-decoration: none;
      }
    }

    // By default, tooltips are kept relatively small
    // Expand them as much as needed
    .p-tooltip {
      max-width: fit-content;
    }
  }
}



app-root {
  height: 100vh;
  display: block;

  .form-main {
    margin: 20px 0px;
    padding: 60px 70px;
    background: #ffffff;
    border-radius: 4px;
    width: 100%;
    max-width: 540px;
    position: relative;
    z-index: 1;

    &.large {
      max-width: 800px;
      padding: 30px;
    }
  }
}

.modal-backdrop.show {
  opacity: 0.7;
}

.modal-sidebar {
  width: 260px !important;
  position: absolute !important;
  top: 0;
  left: 240px;
  height: 400px !important;
  padding: 0.5rem !important;
}
//for horizontal and vertical ribbons on the sidebar that show when resizing
.p-sidebar {
  .p-sidebar-content {
  padding: 0;
  }
  .p-sidebar-content::-webkit-scrollbar{
    display: none;
  }
}

.list-group {
  max-height: 400px;
  margin-bottom: 10px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
td {
  &.truncate {
    max-width: 200px;
  }
  &.truncate-sm {
    max-width: 100px;
  }
}

// Avatar icons
.avatar.bg-grey {
  fa-icon {
    color: #0d47bc;
  }
}

// Align amounts to the right
div.currency {
  text-align: right;
  max-width: 80px;
  white-space: nowrap;
}

// Timeline styling
ul.timeline {
  list-style-type: none;
  position: relative;
}

ul.timeline:before {
  content: ' ';
  background: #5e72e4;
  display: inline-block;
  position: absolute;
  left: 14px;
  width: 2px;
  z-index: 400;
  top: 26px;
  bottom: 26px;
}

ul.timeline > li {
  margin: 25px 0;
  padding-left: 50px;
}

ul.timeline > li:before {
  content: ' ';
  background: #5e72e4;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #5e72e4;
  left: 0;
  width: 30px;
  height: 30px;
  z-index: 400;
  text-align: center;
  margin-top: 16px;
}

ul.timeline > li, .flow-timeline .item-circle {
  &:before {
    font-family: "Font Awesome 5 Free", sans-serif;
    color: white;
  }

  &.future:before {
    background: white !important;
    color: #5e72e4;
  }

  &.reddot:before {
    background-color: $danger;
    border-color: $danger;
  }

  &.greendot:before {
    background-color: $success;
    border-color: $success;
  }

  &.orangedot:before {
    background-color: $warning;
    border-color: $warning;
  }

  &.greydot:before {
    background-color: $secondary;
    border-color: $secondary;
  }

  &.emailicon:before {
    font-weight: 900;
    content: "\f1fa";
  }

  &.smsicon:before {
    font-weight: 900;
    content: "\f3cd";
  }

  &.lettericon:before {
    font-weight: 900;
    content: "\f0e0";
  }

  &.euroicon:before {
    font-weight: 900;
    content: "\f153";
  }

  &.protesticon:before {
    font-weight: 900;
    content: "\f075";
  }

  &.noteicon:before {
    font-weight: 900;
    content: "\f304";
  }

  &.pauseicon:before {
    font-weight: 900;
    content: "\f04c";
  }

  &.playicon:before {
    font-weight: 900;
    content: "\f04b";
  }

  &.stopicon:before {
    font-weight: 900;
    content: "\f04d";
  }

  &.createicon:before {
    font-weight: 900;
    content: "\f15b";
  }

  &.signedicon:before {
    font-weight: 900;
    content: "\f56c";
  }

  &.trashicon:before {
    font-weight: 900;
    content: "\f2ed";
  }

  &.dotsicon:before {
    font-weight: 900;
    content: "\f141";
  }

  &.warningicon:before {
    font-weight: 900;
    content: "\f071";
  }
  
  &.eventicon:before {
    font-weight: 900;
    content: "\f073";
  }
}

@font-face {
  font-family: "poppins-semibold";
  src: url('~assets/fonts/poppins-v9-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Poppins Light'), local('Poppins-Light'),
  url('~assets/fonts/poppins-v9-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('~assets/fonts/poppins-v9-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('~assets/fonts/poppins-v9-latin-300.woff') format('woff'), /* Modern Browsers */
  url('~assets/fonts/poppins-v9-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('~assets/fonts/poppins-v9-latin-300.svg#Poppins') format('svg'); /* Legacy iOS */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppins-regular";
  src: url('~assets/fonts/poppins-v9-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Poppins Regular'), local('Poppins-Regular'),
  url('~assets/fonts/poppins-v9-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('~assets/fonts/poppins-v9-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('~assets/fonts/poppins-v9-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('~assets/fonts/poppins-v9-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('~assets/fonts/poppins-v9-latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppins-light";
  src: url('~assets/fonts/poppins-v9-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Poppins SemiBold'), local('Poppins-SemiBold'),
  url('~assets/fonts/poppins-v9-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('~assets/fonts/poppins-v9-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('~assets/fonts/poppins-v9-latin-600.woff') format('woff'), /* Modern Browsers */
  url('~assets/fonts/poppins-v9-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('~assets/fonts/poppins-v9-latin-600.svg#Poppins') format('svg'); /* Legacy iOS */
  font-weight: normal;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "poppins-semibold";
  color: #2b3035;
}

.tag {
  border-radius: 5px;
  padding: 3px 6px;
  text-align: center;
}

.red {
  background-color: #f44336;
  color: #fff;
}

.pink {
  background-color: #e91e63;
  color: #fff;
}

.purple {
  background-color: #9c27b0;
  color: #fff;
}

.deep-purple {
  background-color: #673ab7;
  color: #fff;
}

.indigo {
  background-color: #3f51b5;
  color: #fff;
}

.blue {
  background-color: #2196f3;
  color: #fff;
}

.light-blue {
  background-color: #03a9f4;
  color: #fff;
}

.cyan {
  background-color: #00bcd4;
  color: #fff;
}

.teal {
  background-color: #009688;
  color: #fff;
}

.green {
  background-color: #4caf50;
  color: #fff;
}

.light-green {
  background-color: #8bc34a;
  color: rgba(0, 0, 0, .85);
}

.lime {
  background-color: #cddc39;
  color: rgba(0, 0, 0, .85);
}

.yellow {
  background-color: #ffeb3b;
  color: rgba(0, 0, 0, .85);
}

.amber {
  background-color: #ffc107;
  color: rgba(0, 0, 0, .85);
}

.orange {
  background-color: #ff9800;
  color: rgba(0, 0, 0, .85);
}

.deep-orange {
  background-color: #ff5722;
  color: #fff;
}

.brown {
  background-color: #795548;
  color: #fff;
}

.blue-grey {
  background-color: #607d8b;
  color: #fff;
}

.grey {
  background-color: #9e9e9e;
  color: #fff;
}


.nospinners {
  /* Chrome, Safari, Edge, Opera */
  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}

// Lay out for filters on a table
.mw-20px {
  min-width: 20px;
  height: auto;
}

.tablefilters {
  background-color: rgba(135, 150, 165, 0.15);
  border-radius: 10px;
  padding: 20px;
}

.clickable {
  cursor: pointer;
}

.placeholder {
  color: $text-muted;
  font-style: italic;
}

.translated-paragraph {
  white-space: pre-wrap;
}


.custom-dialog {
  &.dialog-vw-75 {
    width: 75vw;
  }

  &.dialog-vw-50 {
    width: 50vw;
  }

  &.dialog-vw-40 {
    width: 40vw;
  }

  &.p-dialog {
    background-color: $bg-color-blue;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05), 0 -1px 0px rgba(0, 0, 0, 0.015);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05), 0 -1px 0px rgba(0, 0, 0, 0.015);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05), 0 -1px 0px rgba(0, 0, 0, 0.015);

    .p-dialog-header {
      padding: 10px 20px;

      .p-dialog-title {
        font-size: 1.2rem;
        font-family: "poppins-regular";
        font-weight: 400;
      }

      .p-dialog-titlebar-close {
        font-size: 1.2rem;
        font-weight: 600;
      }
    }

    .p-dialog-content {
      color: $custom-dark-gray;
      background-color: $bg-color-blue;
      font-family: "poppins-regular";
    }

    .p-dialog-footer {
      box-shadow: 0px -1px 3px 0px rgba(30, 30, 30, 0.2);
      background-color: rgba(0, 0, 0, 0.15);
      padding: 20px;
      border-top: 0;

      p-footer {
        width: 100%;
        display: flex;
      }

      display: flex;
    }
  }

}

fa-icon {
  svg {
    vertical-align: middle !important;
  }

  &.inline-button {
    cursor: pointer;
    padding: 0px 10px;

    &:hover {
      color: #78dedf;
    }
  }

  &.primary {
    color: $primary;
  }
}

// Dashboard
div.dashboard-item {
  min-height: 250px;

  canvas {
    max-width: 700px;
  }

  table {
    height: 90%;
    width: 100%;
  }

  div.spinner-border {
    width: 3rem;
    height: 3rem;
  }
}

.blue-bg {
  background: #26368a;
}

.todo-rows{
  width: 100%;
  padding: 10px;
  border: none;
  outline: none;
  text-align: left;
  background-color: #F7F8FA;
  color: #212529;
  padding: 16px;
  cursor:pointer;
  &:hover{
    background: #efefef;
    ~ .todo-number {
      background: #efefef;
    }
  }
}

.todo-information{
display: flex!important;
align-items: center;
justify-content: space-between;
margin-bottom: 1rem;
}

.todo-number{
  display: flex;
  align-items: center;
  justify-content: center;
  padding:16px;
  background-color: #F7F8FA;
  color: #212529;
  margin-right: 2rem;
  margin-left:1rem;
  width:3rem;
}

.color-picker {
  width: 20%;
}
